import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loader", "icon", "responseContent", "responseStatus", "authMessage", "authMessageTemplate", "object", "pre"]
  static outlets = ["syntax-highlight"]

  handleResponse(event) {
    const responsePromise = event.detail.responsePromise
    const contentType = responsePromise.headers.get("content-type")

    switch(contentType) {
    case "image/png":
    case "image/jpeg":
      this.#handleImage(responsePromise, contentType)
      break
    default:
      responsePromise.arrayBuffer().then((data) => {
        let content

        // Attempt to parse JSON
        try {
          content = JSON.parse(data)
        } catch {
          content = data
        }

        this.#setResponseStatusAndContent(responsePromise.status, JSON.stringify(content, null, 2))
      })
    }
  }

  handleFailure(event) {
    const error = event.detail.responsePromise

    this.#unexpectedErrorHandler(error)
  }

  #unexpectedErrorHandler(error) {
    this.#setResponseStatusAndContent(error.name, error.message)
  }

  #setResponseStatusAndContent(status, content) {
    this.#setLoadingState(false)
    this.responseStatusTarget.innerText = status
    this.responseStatusTarget.dataset.statusCode = status
    this.responseStatusTarget.removeAttribute("hidden")
    this.preTarget.removeAttribute("hidden")
    this.responseContentTarget.textContent = content
    if (status === 401) {
      if (!this.hasAuthMessageTarget) {
        const template = this.authMessageTemplateTarget.content.cloneNode(true)
        const responseContainer = this.responseContentTarget.closest(".example-content")
        responseContainer.prepend(template)
      }
      this.dispatch("check-auth", { target: document })
    } else if (this.hasAuthMessageTarget) {
      this.authMessageTarget.remove()
    }
    this.responseContentTarget.removeAttribute("data-highlighted")
    this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.responseContentTarget)).highlight()
  }

  #setLoadingState(isLoading) {
    this.loaderTarget.setAttribute("aria-hidden", !isLoading)
    this.loaderTarget.setAttribute("aria-busy", isLoading)
    this.iconTarget.setAttribute("aria-hidden", isLoading)
  }

  #handleImage(responsePromise, contentType) {
    responsePromise.arrayBuffer().then((data) => {
      const blob = new Blob([data], { type: contentType })
      const imageUrl = URL.createObjectURL(blob)
      const image = document.createElement("img")
      image.src = imageUrl
      this.objectTarget.replaceChildren(image)
      this.preTarget.setAttribute("hidden", true)
      this.#setLoadingState(false)
    })
  }
}
