import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["apiKey", "requestForm", "serverUrl", "server", "operationLink"]

  static values = { documentationId: String }

  connect() {
    this.localStorageName = `documentation:${this.documentationIdValue}:api-key`

    // Get the serverId and the authenticationId from current URL at controller loading
    const serverId = new URLSearchParams(window.location.search).get("server_id")
    const authenticationId = new URLSearchParams(window.location.search).get("authentication_id")

    // Set the server_id query param on operation url
    if (serverId) {
      this.#setParamInOperationUrl("server_id", serverId)
    }
    // Set the authentication_id param on operation url
    if (authenticationId) {
      this.#setParamInOperationUrl("authentication_id", authenticationId)
    }
    this.#setDomApiKeyFromLocalStorage()
  }

  changeAuthentication() {
    const authentication = this.element.querySelector("[data-selected-scheme=true]")
    const authenticationId = authentication.dataset.schemeId

    this.#setParamInOperationUrl("authentication_id", authenticationId)
    this.#setParamUrl("authentication_id", authenticationId)

    this.#setDomApiKeyFromLocalStorage()

    // Use to update the request
    const apiKey = this.apiKeyTarget.value
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  changeServer(event) {
    const server = event.currentTarget.parentElement
    const serverId = server.dataset.id

    this.#setParamInOperationUrl("server_id", serverId)
    this.#setParamUrl("server_id", serverId)

    // TODO EXPLORER: Code to move in other file
    const clickedButton = event.currentTarget
    const sendRequestController = this.application.getControllerForElementAndIdentifier(this.element, "explorer-send-request")
    this.serverUrlTarget.textContent = server.dataset.url
    sendRequestController.updateServer(server.dataset.url)

    this.element.querySelectorAll("button").forEach((button) => button.classList.remove("selected"))
    clickedButton.classList.add("selected")
    // until here

    this.#setDomApiKeyFromLocalStorage()

    // Use to update the request
    const apiKey = this.apiKeyTarget.value
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  resetApiKey() {
    this.apiKeyTarget.value = ""
    const apiKeys = this.#retrieveApiKeysFromLocalStorage()
    const serverApiKeys = apiKeys[this.#currentServerLocalStorageKey()]
    //  Clear old string
    if ((serverApiKeys != null) && (typeof (serverApiKeys) === "object")) {
      delete apiKeys[this.#currentServerLocalStorageKey()][this.#currentAuthenticationLocalStorageKey()]
      if (Object.keys(apiKeys[this.#currentServerLocalStorageKey()]).length == 0) {
        delete apiKeys[this.#currentServerLocalStorageKey()]
      }
    }
    localStorage.setItem(this.localStorageName, JSON.stringify(apiKeys))
    this.dispatch("updatedApiKey", { detail: { apiKey: "" } })
  }

  // let users get entered tokens back when switching servers
  // TODO EXPLORER: add js helper debounce
  updateApiKey(event) {
    const apiKey = event.currentTarget.value
    if (apiKey === "") {
      return this.resetApiKey()
    }
    const apiKeys = this.#retrieveApiKeysFromLocalStorage()
    const serverApiKeys = apiKeys[this.#currentServerLocalStorageKey()]
    if ((serverApiKeys === null) || (typeof (serverApiKeys) !== "object")) {
      apiKeys[this.#currentServerLocalStorageKey()] = { }
    }
    apiKeys[this.#currentServerLocalStorageKey()][this.#currentAuthenticationLocalStorageKey()] = apiKey
    localStorage.setItem(this.localStorageName, JSON.stringify(apiKeys))
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  #setParamUrl(key, value) {
    let params = new URLSearchParams(window.location.search)
    params.set(key, value)
    const newUrl = new URL(window.location)
    newUrl.search = params.toString()
    history.pushState({}, "", newUrl)
  }

  #setDomApiKeyFromLocalStorage() {
    if (this.#hasNoApiKey()) {
      return
    }
    const apiKey = this.#getLocalStorageApiKey() || ""
    this.apiKeyTarget.value = apiKey
  }

  #retrieveApiKeysFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.localStorageName)) || {}
  }

  #currentServerLocalStorageKey() {
    return this.serverUrlTarget.textContent.trim()
  }

  #currentAuthenticationLocalStorageKey() {
    const authentication = this.element.querySelector("[data-selected-scheme=true]")
    return authentication.dataset.schemeId
  }

  #setParamInOperationUrl(key, value) {
    if (!value) {
      return
    }

    this.operationLinkTargets.forEach((operationLink) => {
      const url = new URL(operationLink.href)
      let operationLinkParams = new URLSearchParams(url.search)

      operationLinkParams.set(key, value)

      url.search = operationLinkParams.toString()
      operationLink.href = url.toString()
    })
  }

  #hasNoApiKey() {
    return this.apiKeyTargets.length === 0
  }

  #getLocalStorageApiKey() {
    const apiKeys = this.#retrieveApiKeysFromLocalStorage()
    const serverApiKeys = apiKeys[this.#currentServerLocalStorageKey()]
    if ((serverApiKeys != null) || (typeof (serverApiKeys) === "object")) {
      return apiKeys[this.#currentServerLocalStorageKey()][this.#currentAuthenticationLocalStorageKey()]
    }
    return null
  }
}
