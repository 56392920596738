import { Controller } from "@hotwired/stimulus"
import { serializeJSON } from "@syneto/serializejson"
import { trackAnalytics } from "../../global/javascript/track_analytics"
import RequestBuilder from "../../global/javascript/request_builder"
import RequestToCurl from "../../global/javascript/request_to_curl"
import { isEmpty, capitalize, base64 } from "../../global/javascript/utils"

export default class extends Controller {
  static targets = ["requestForm", "urlForm", "preview", "icon", "loader", "authSchemeOption"]

  static values = { enctype: String, operationPath: String, baseUrl: String, jwtToken: String, trackUrl: String, proxyUrl: String, }

  // Response elements which needs to interact with the Syntax-Highlight controller
  static outlets = ["syntax-highlight", "explorer-collection"]

  connect() {
    this.#initializeRequestBuilder()
    this.updateRequest()
  }

  // For now we have a unique entrypoint to handle both successfully
  // (2xx) or errored (all other status) responses
  //
  // The event object has three elements which are defined by Rails UJS
  // https://github.com/rails/rails/blob/v7.1.3.4/actionview/app/assets/javascripts/rails-ujs.js#L91-L99

  onSubmit(event) {
    event.preventDefault()

    if (!this.#isLoadingState()) {
      this.#setLoadingState(true)
      this.#submitFormData()

      trackAnalytics(this.trackUrlValue)
    }
  }

  validateAndSubmitForm(event, form) {
    event.preventDefault()
    return form.checkValidity() || (form.reportValidity(), false)
  }

  handleKeydownForBothForms(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      const isUrlFormValid = this.validateAndSubmitForm(event, this.urlFormTarget)
      const isRequestFormValid = this.validateAndSubmitForm(event, this.requestFormTarget)
      if (isUrlFormValid && isRequestFormValid) {
        this.onSubmit(event)
      }
    }
  }

  updateApiKey(event) {
    if (this.#hasNoAuthenticationSchemes()) {
      return
    }
    const credential = event.detail.apiKey
    const destination = this.#selectedAuthenticationSchemeDestination().toLowerCase()

    switch (destination) {
    case "user":
      this.#addCredentialInHeader(base64.encodeString(credential))
      break
    case "query":
      this.#addCredentialInQuery(credential)
      break
    case "cookie":
      this.#addCredentialInCookie(credential)
      break
    case "header":
    default:
      this.#addCredentialInHeader(credential)
      break
    }

    this.updateRequest()
  }

  resetRequest() {
    this.explorerCollectionOutlets.map((controller) => {
      controller.removeAll()
    })
    // Finally update the request (by default the collection.removeAll
    // function doesn't update the request)
    this.updateRequest()
  }

  updateRequest() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const serializedUrlForm = serializeJSON(this.urlFormTarget, {
        skipFalsyValuesForTypes: ["boolean", "number", "string"]
      })
      let serializedRequestForm = serializeJSON(this.requestFormTarget, {
        skipFalsyValuesForTypes: ["boolean", "number", "string"],
        useIntKeysAsArrayIndex: true
      })
      serializedRequestForm = this.#removeSerializedNulls(serializedRequestForm)

      const pathParameters = serializedUrlForm["path_parameters"]
      const queryParameters = serializedUrlForm["query_parameters"]
      this.requestBuilder.updateUrl(this.#computeUrl(pathParameters, queryParameters))

      if (serializedUrlForm["headers"]) {
        Object.entries(serializedUrlForm["headers"]).forEach(([key, value]) => this.requestBuilder.setHeader(key, value))
      }
      this.requestBuilder.buildCookies()

      if (serializedRequestForm) {
        delete serializedRequestForm._method
      }

      if (!isEmpty(serializedRequestForm)) {
        this.requestBuilder.setBody(serializedRequestForm)
      } else {
        this.requestBuilder.unsetBody()
      }

      this.#generatePreview()
    }, 500)
  }

  updateServer(server) {
    this.baseUrlValue = server
    this.updateRequest()
  }

  #generatePreview() {
    const requestToCurl = new RequestToCurl(this.requestBuilder.build(), { blacklist: { headers: ["X-Bump-Proxy-Token"] } })
    const curlSnippet = requestToCurl.convert()
    // Curly Braces are not URL safe so they are escape by the lib
    // We add them back for a nicer display experience
    const displayableCurlSnippet = curlSnippet.replaceAll("%7B", "{").replaceAll("%7D", "}")
    this.previewTarget.innerHTML = displayableCurlSnippet

    const outlet = this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.previewTarget))
    if (outlet) {
      outlet.highlight()
    }
  }

  #setLoadingState(isLoading) {
    this.loaderTarget.setAttribute("aria-hidden", !isLoading)
    this.loaderTarget.setAttribute("aria-busy", isLoading)
    this.iconTarget.setAttribute("aria-hidden", isLoading)
  }

  // Replace path parameters in the given URL and add query parameters
  // as an URL encoded query string.
  #computeUrl(pathParameters, queryParameters) {
    let rawPath = this.operationPathValue

    // Interpolate path parameter into rawPath
    if (pathParameters) {
      const filteredPathParameters = Object.entries(pathParameters).filter(this.#presentValuesFromEntries)
      rawPath = filteredPathParameters.reduce((acc, [key, value]) => acc.replace(`{${key}}`, value), rawPath)
    }

    // Join base URL with operation path (working with trailing slash
    // on base URL or not)
    let targetUrl = new URL(`.${rawPath}`, `${this.baseUrlValue}/`)
    targetUrl.pathname = targetUrl.pathname.replace("//", "/")

    if (queryParameters) {
      // Remove blank query params
      const filteredQueryParameters = Object.fromEntries(
        Object.entries(queryParameters).filter(this.#presentValuesFromEntries)
      )
      targetUrl.search = new URLSearchParams(filteredQueryParameters)
    }

    return targetUrl.toString()
  }

  #presentValuesFromEntries([, value]) {
    return value !== null && value !== undefined && value !== ""
  }

  #submitFormData() {
    const self = this
    let requestOptions = this.requestBuilder.build()
    let [url, proxyfiedRequestOptions] = this.#maybeProxify(requestOptions)

    fetch(url, proxyfiedRequestOptions)
      .then((responsePromise) => {
        self.dispatch(
          "requestSent",
          {
            detail: {
              responsePromise
            }
          }
        )
      }).catch((responsePromise) => {
        self.dispatch(
          "requestFailed",
          {
            detail: {
              responsePromise,
              requestHostname: self.requestBuilder.url.hostname
            }
          }
        )
      })
  }

  // Read the request method either from the hidden "_method" field or
  // from the form directly. This hidden field is the Rails magic for
  // PUT, PATCH or DELETE requests (because HTML forms only support
  // GET or POST requests)
  #retrieveFormMethod(form) {
    const serializedFormData = serializeJSON(form, {
      skipFalsyValuesForTypes: ["boolean", "number", "string"]
    })
    const method = serializedFormData["_method"] || form.method
    delete serializedFormData["_method"]
    return method
  }

  #isLoadingState() {
    return this.loaderTarget.ariaBusy === "true"
  }


  #initializeRequestBuilder() {
    this.requestBuilder = new RequestBuilder(this.#computeUrl(null, null))
    this.requestBuilder.setMethod(this.#retrieveFormMethod(this.requestFormTarget))

    if (this.enctypeValue) {
      this.requestBuilder.setHeader("Content-Type", this.enctypeValue)
    }
  }


  #hasNoAuthenticationSchemes() {
    return !this.element.querySelector("[data-selected-scheme=true]")
  }

  // This is arbitrary
  // We do not want the user to select
  // The authentication scheme yet
  // So we select the first one even though
  // Some documentations may allow multiple auth schemes

  #selectedAuthenticationScheme() {
    if (this.#hasNoAuthenticationSchemes()) {
      return
    }

    const selectedElement = this.element.querySelector("[data-selected-scheme='true']")
    const dataset = Object.assign({}, selectedElement.dataset)
    const { authType, scheme, parameterName, destination } = dataset
    const name = selectedElement.textContent.trim()
    return {
      name,
      parameterName,
      destination,
      scheme,
      authType
    }
  }

  #selectedAuthenticationSchemeAuthType() {
    return this.#selectedAuthenticationScheme()?.authType
  }

  #selectedAuthenticationSchemeScheme() {
    return this.#selectedAuthenticationScheme()?.scheme
  }

  #selectedAuthenticationSchemeParameterName() {
    return this.#selectedAuthenticationScheme()?.parameterName
  }

  #selectedAuthenticationSchemeDestination() {
    return this.#selectedAuthenticationScheme()?.destination
  }

  #addCredentialInHeader(credential) {
    const scheme = this.#selectedAuthenticationSchemeScheme()
    const headerValue = [capitalize(scheme), credential].filter(present => present).join(" ")
    const name = this.#selectedAuthenticationSchemeParameterName()
    if (credential) {
      if (name) {
        this.requestBuilder.setHeader((name), headerValue)
      } else {
        this.requestBuilder.setHeader("Authorization", headerValue)
      }
    } else {
      name && this.requestBuilder.deleteHeader(name)
    }
  }

  #addCredentialInCookie(credential) {
    const name = this.#selectedAuthenticationSchemeParameterName()
    if (name && credential) {
      this.requestBuilder.addCookie(name, credential)
    } else {
      this.requestBuilder.deleteCookie(name)
    }
  }

  #addCredentialInQuery(credential) {
    if (this.#selectedAuthenticationSchemeParameterName()) {
      this.requestBuilder.addQueryParam(this.#selectedAuthenticationSchemeParameterName(), credential)
    }
  }

  #isDifferentHostname(url) {
    return window.location.hostname != url.hostname
  }

  #maybeProxify(requestOptions) {
    const { url } = requestOptions
    let targetUrl

    delete requestOptions.url

    if (this.#isDifferentHostname(url) && (this.proxyUrlValue.trim().length > 0)) {
      targetUrl = `${this.proxyUrlValue}/${url.toString()}`
      requestOptions.headers.set("x-bump-proxy-token", this.jwtTokenValue)
    } else {
      targetUrl = url.toString()
    }

    return [targetUrl, requestOptions]
  }

  // Remove null value inside arrays by constructor of a sibling
  #removeSerializedNulls(serializedForm) {
    const traverse = require("traverse")
    return traverse(serializedForm).map(function (schema) {
      if (Array.isArray(schema)) {
        this.update(schema.filter(n => n))
      }
    })
  }
}
